
import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
    computed: {
      ...mapGetters({
        fullPrice: 'Cart/fullPrice'
      })
    }
})
export default class ExchangeCartItem extends Vue {
  showFullPriceFormat!: any

  @Prop()
  sku!: any

  removeSku (): void {
    this.$emit('removeSku')
  }
}



import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import ProductProvider from '@/resources/product.provider'
import { mapProduct } from '@/utils/product.utils'
import * as onScan from 'onscan.js'
import keyCodeMapperCharacter from '@/utils/keycodemap.utils'

const ProductService = new ProductProvider()

@Component({
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch
    }),
    ...mapGetters({
      cartAmount: 'ExchangeOrder/cartAmount'
    })
  },
  methods: {
    ...mapActions({
      addToCart: 'ExchangeOrder/addToCart'
    })
  }
})
export default class ExchangeScanProduct extends Vue {
  loading = false

  addToCart!: any;

  selectBranch!: any;

  products = []

  mounted (): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this
    onScan.attachTo(document, {
        suffixKeyCodes: [13],
        reactToPaste: true,
        onScan (sCode) {
          const patternUrl = /^(https:\/\/www\.gentlewomanonline\.com\/|https:\/\/gentlewomanonline\.com\/|https:\/\/www\.matter-makers\.com\/|https:\/\/matter-makers\.com\/)/
          if (patternUrl.test(sCode.toLowerCase())) {
            const splitCodeFromUrl = sCode.split('/')
            const code = splitCodeFromUrl[splitCodeFromUrl.length - 1]
            if (code) _this.searchProduct(code)
          }
        },
        keyCodeMapper (oEvent: any) {
          if (keyCodeMapperCharacter[+oEvent.keyCode] !== undefined) {
            return keyCodeMapperCharacter[+oEvent.keyCode][+oEvent.shiftKey]
          }
          return onScan.decodeKeyEvent(oEvent)
        }
    })
  }

  addItem (item: any): void {
    this.addToCart({ ...item, operation: 'new_item' })
  }

  async searchProduct (search: string): Promise<void> {
    this.loading = true
    this.$emit('loadingTrigger', true)
    const { data: { results } } = await ProductService.getProduct({ search })
      this.products = mapProduct(results, this.selectBranch, search)
    if (this.products.length === 0) {
      this.$buefy.toast.open({ message: 'Product not found', type: 'is-danger' })
    } else {
        this.addItem(this.products[0])
    }
    this.$emit('loadingTrigger', false)
    this.loading = false
  }

  // eslint-disable-next-line class-methods-use-this
  beforeDestroy (): void {
    onScan.detachFrom(document)
  }
}
